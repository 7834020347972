<!-- ----------------------------------------------------------------------- -->
<!--
name    : APP ESTIMATE PROPOSAL

type    : view

uses    : work-task-sections-list-readonly
          simple-dialog-template
          inventory-map
          header-view
          main-container
          main-card
          progressDialog

route   : /estimates/proposal/:uuid
 -->
<!-- ----------------------------------------------------------------------- -->
<template>
  <div class="app-estimate-proposal" id="background" v-if="componentCreated">
    <header-view
      title="Preview Estimate"
      :btnOptions="headerButtons"
      :id="'EST-' + estimate.number.toString().padStart(5, '0')"
      :previous_page="
        $auth.isAuthenticated
          ? '/jobs/view/' + estimate.job_uuid
          : 'NO_BACK_BUTTON'
      "
      :allow_edit="
        $auth.isAuthenticated &&
        this.estimate.status != 'Declined' &&
        this.estimate.status != 'Approved'
      "
      :edit_url="'/estimates/view/' + estimate.uuid"
      :allow_download="true"
      :download_url="this.mostRecentProposalUrl"
      :status="$auth.isAuthenticated ? estimate.status : ''"
      :job_uuid="$auth.isAuthenticated ? estimate.job_uuid : ''"
      view_phase="Estimate" />
    <main-container>
      <main-card>
        <v-layout align-start v-if="treeCareLogo">
          <v-flex xs2>
            <v-img
              position="left"
              class="mt-2 ml-4"
              height="64"
              contain
              :src="treeCareLogo"></v-img>
          </v-flex>
          <v-flex xs10 class="mt-6">
            <span class="display-1 my-4">Estimate of Work</span>
          </v-flex>
        </v-layout>
        <v-layout align-start v-else>
          <v-flex xs12>
            <span class="display-1">Estimate of Work</span>
          </v-flex>
        </v-layout>
        <v-divider class="my-2"></v-divider>
        <v-layout row wrap id="estimateHeaderRow" class="mx-7">
          <v-flex md4 px-2 mt-2>
            <div class="title">Client Information</div>
            <div class="subheading">{{ client.client_name }}</div>
            <div class="subheading">{{ client.contact_name }}</div>
            <div class="subheading">{{ client.contact_phone }}</div>
            <div class="subheading">{{ client.contact_email }}</div>
          </v-flex>
          <v-flex md4 px-2 mt-2>
            <div class="title">Job Site</div>
            <div class="subheading">{{ jobSite.address }}</div>
            <div class="subheading">
              {{ jobSite.city }}, {{ jobSite.state }} {{ jobSite.zip_code }}
            </div>
          </v-flex>
          <v-flex md4 px-2 mt-2>
            <v-layout row>
              <v-flex xs12 class="title pr-2"> Company Contact </v-flex>
            </v-layout>
            <v-layout row>
              <v-flex xs4 class="pr-2">
                <b>Created on:</b>
              </v-flex>
              <v-flex xs8 md6>
                {{
                  displayDateFormat(
                    estimate.date_created,
                    this.tenantSettings.date_format
                  )
                }}
              </v-flex>
            </v-layout>

            <v-layout row>
              <v-flex xs4 class="pr-3">
                <b>Estimator:</b><br />
                <div v-if="estimator.email"><b>Email:</b><br /></div>
                <div v-if="estimator.phone"><b>Phone:</b></div>
              </v-flex>
              <v-flex xs8>
                <span>{{ estimator.name || 'Unavailable' }}</span
                ><br />
                <div v-if="estimator.email">
                  <span>{{ estimator.email }}</span
                  ><br />
                </div>
                <div v-if="estimator.phone">{{ estimator.phone }}</div>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
        <v-layout align-end row wrap id="estimateDataRow" class="mx-7">
          <v-flex xs12 md4 class="mapToggleSwitch mt-2">
            <v-btn-toggle v-model="tableView" mandatory color="primary">
              <v-btn
                :color="!tableView ? '#035185' : ''"
                :style="
                  !tableView ? 'border: 3px solid #004370 !important;' : ''
                ">
                <v-icon :color="!tableView ? 'white' : ''"
                  >mdi-map-marker</v-icon
                >
              </v-btn>
              <v-btn
                :color="tableView ? '#035185' : ''"
                :style="
                  tableView ? 'border: 3px solid #004370 !important;' : ''
                ">
                <v-icon :color="tableView ? 'white' : ''"
                  >mdi-format-list-bulleted</v-icon
                >
              </v-btn>
            </v-btn-toggle>
          </v-flex>
        </v-layout>
        <v-layout
          class="mx-3"
          wrap
          id="estimateDataRow"
          v-if="
            estimate.status == 'In Progress' ||
            estimate.status == 'Approved' ||
            estimate.status == 'Sent' ||
            estimate.status == 'Revising' ||
            estimate.status == 'Declined'
          ">
          <v-flex xs12>
            <!-- This is the second for the table toggle -->
            <work-task-sections-list-readonly
              v-if="estimate"
              ref="workTasks"
              v-bind:proposal_view="estimate.status != 'Declined'"
              v-bind:approved="estimate.status == 'Approved'"
              v-bind:estimate="estimate"
              v-bind:tenantSettings="tenantSettings"
              v-bind:trees="trees"
              v-show="tableView"
              @approvalsChanged="generatePDF" />
          </v-flex>
          <v-flex xs12 mt-3 v-if="!tableView">
            <inventory-map
              :readonly="true"
              v-bind:jobsites="[jobSite]"
              v-bind:tenantSettings="tenantSettings"
              :can_add_trees="false"
              v-bind:client="client"
              v-bind:estimate="estimate"
              v-bind:trees="trees"
              inventory_type="trees"
              v-bind:selected_jobsite_uuid="estimate.job_site_uuid"
              ref="inventoryMap"
              proposal />
          </v-flex>
        </v-layout>
        <v-layout row mb-3 class="mx-7">
          <v-flex xs-12>
            <span class="display-1">Terms & Conditions</span>
          </v-flex>
        </v-layout>
        <v-layout row wrap class="mx-7">
          <v-flex xs12>
            <div>
              <p v-if="estimate.terms">
                {{ estimate.terms }}
              </p>
            </div>
          </v-flex>
        </v-layout>
        <v-divider class="py-4"></v-divider>
        <v-layout row pt-4 class="mx-7 mt-9">
          <v-flex xs5>
            <v-divider></v-divider>
          </v-flex>
          <v-flex xs2> </v-flex>
          <v-flex xs5>
            <v-divider></v-divider>
          </v-flex>
        </v-layout>
        <v-layout row class="mx-7">
          <v-flex xs5>
            <v-subheader>Print Client Name</v-subheader>
          </v-flex>
          <v-flex xs2> </v-flex>
          <v-flex xs5>
            <v-subheader>Print Rep. Name</v-subheader>
          </v-flex>
        </v-layout>
        <v-layout row mt-4 class="mx-7">
          <v-flex xs5>
            <v-divider></v-divider>
          </v-flex>
          <v-flex xs2> </v-flex>
          <v-flex xs5>
            <v-divider></v-divider>
          </v-flex>
        </v-layout>
        <v-layout row class="mx-7">
          <v-flex xs5>
            <v-subheader>Signed Client Name - Date</v-subheader>
          </v-flex>
          <v-flex xs2> </v-flex>
          <v-flex xs5>
            <v-subheader>Signed Rep. Name - Date</v-subheader>
          </v-flex>
        </v-layout>
      </main-card>
    </main-container>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-snackbar v-model="snackbar" :timeout="5000">
      {{ snackbar_message }}
      <v-btn color="blue" text @click="snackbar = false"> Close </v-btn>
    </v-snackbar>

    <simple-dialog-template
      :open="submitEstimateConfirm"
      dialogTitle="Send Estimate to Client"
      dialogText="Are you sure you would like to send this proposal to your client?"
      dialogButtonOne="Yes"
      dialogButtonTwo="No"
      @buttonOne="onSubmitEstimate"
      @buttonTwo="submitEstimateConfirm = false" />
    <simple-dialog-template
      :open="declineEstimateConfirm"
      dialogTitle="Decline Estimate"
      dialogText="Are you sure you would like to decline this estimate proposal? You will not be able to make edits to this estimate in the future."
      dialogButtonOne="Yes"
      dialogButtonTwo="No"
      @buttonOne="declineProposal"
      @buttonTwo="declineEstimateConfirm = false" />

    <v-dialog v-model="revisionCommentDialog" persistent max-width="600px">
      <v-card color="grey lighten-4" min-width="350px" flat>
        <header-view
          dialog
          previous_page="NO_BACK_BUTTON"
          title="Add Estimate Comments"
          closeBtn
          :closeAction="
            () => {
              revisionCommentDialog = false;
            }
          "
          :btnOptions="[
            {
              name: '',
              btnColor: 'white',
              icon: 'mdi-check',
              action: this.reviseEstimate,
            },
          ]" />

        <v-card-text>
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex xs12>
                <v-textarea
                  id="revision_comment"
                  v-model="revision_comment"
                  label="Comments to Revise Estimate"></v-textarea>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="sendOrCopyDialog" width="500" eager>
      <v-card color="grey lighten-4" min-width="350px" flat>
        <header-view
          dialog
          previous_page="NO_BACK_BUTTON"
          title="Send Proposal to Client"
          closeBtn
          :closeAction="
            () => {
              sendOrCopyDialog = false;
            }
          "
          :btnOptions="[]" />
        <v-card-text justify="center">
          <!-- Send an email directly to your client with a link to this esitmate, or  -->
          Please copy the link and share it with your client
          <v-row class="pa-2">
            <v-col>
              <v-btn block class="button-primary" @click="onSubmitEstimate">
                Send Email to Client
              </v-btn>
            </v-col>
            <v-col>
              <v-btn
                block
                class="button-secondary"
                @click="copyURLToClipboard()">
                Copy Link
              </v-btn>
            </v-col>
          </v-row>
          <v-text-field
            class="offscreen"
            id="proposalURLField"
            :value="proposalURL"></v-text-field>
        </v-card-text>
      </v-card>
    </v-dialog>

    <progress-dialog v-model="busy" header="Processing" body="Processing">
    </progress-dialog>
  </div>
</template>

<script>
  import WorkTaskSectionsListReadOnly from '@/components/worktasks/work-task-sections-list-readonly';
  import email from '@/mixins/email';
  import dateTimeHelpers from '@/mixins/dateTimeHelperFunctions';
  import progressDialog from '@/components/helpers/ProgressDialog';
  import SimpleDialogTemplate from '@/components/simple-dialog-template';
  import InventoryMap from '@/components/inventory-map';
  import HeaderView from '@/components/header-view';
  import MainContainer from '@/components/main-container';
  import MainCard from '@/components/main-card';
  import Localization from '@/mixins/localization';
  import Clients from '@/services/Clients.service.js';
  import Jobs from '@/services/Jobs.service.js';
  import Users from '@/services/Users.service.js';
  import Tenants from '@/services/Tenants.service.js';

  export default {
    name: 'AppEstimateProposal',
    components: {
      'work-task-sections-list-readonly': WorkTaskSectionsListReadOnly,
      'simple-dialog-template': SimpleDialogTemplate,
      'inventory-map': InventoryMap,
      'header-view': HeaderView,
      'main-container': MainContainer,
      'main-card': MainCard,
      progressDialog,
    },
    mixins: [email, dateTimeHelpers, Localization],
    props: {
      uuid: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        busy: false,
        componentCreated: false,
        componentMounted: false,
        trees: [],
        snackbar_message: '',
        menu: false,
        submitEstimateConfirm: false,
        declineEstimateConfirm: false,
        sendOrCopyDialog: false,
        revision_comment: '',
        revisionCommentDialog: false,
        snackbar: false,
        estimate: {},
        selectedEstimator: undefined,
        client: {},
        jobSite: {},
        overlay: false,
        dialogButtonOne: {},
        tableView: 1,
        treeCareLogo: require('../../assets/TP-Jobs-RGB.png'),
        estimator: {},
        mostRecentProposalUrl: undefined,
        hidden_total_price: 0,
        tenant: {},
        allowedToDecline: false,
        tenantSettings: {},
      };
    },
    computed: {
      proposalURL() {
        return window.location.href;
      },
      tenant_uuid: function () {
        if (this.tenant) {
          return this.tenant.uuid;
        } else {
          return this.estimate.tenant_uuid || '';
        }
      },
      headerButtons() {
        const buttons = [];

        if (this.estimate.status == 'Declined') {
          return;
        }

        if (this.estimate.status != 'Approved') {
          buttons.unshift({
            name: 'Approve',
            btnColor: 'button-primary',
            action: this.approveProposal,
            disabled: !(this.estimate.status != 'Approved'),
          });

          if (this.estimate.status == 'Sent') {
            buttons.unshift({
              name: 'Request Change',
              btnColor: 'button-tertiary',
              action: () => {
                this.revisionCommentDialog = true;
              },
            });
          }

          if (this.allowedToDecline) {
            buttons.push({
              name: 'Decline',
              btnColor: 'button-secondary',
              action: () => {
                this.declineEstimateConfirm = true;
              },
            });
          }
        }

        if (this.$auth.isAuthenticated) {
          if (
            this.estimate.status != 'Approved' &&
            this.estimate.status != 'Sent'
          ) {
            buttons.unshift({
              name: 'Send',
              btnColor: 'button-tertiary',
              action: () => {
                this.sendOrCopyDialog = true;
              },
            });
          }
        }

        return buttons;
      },
    },
    watch: {},
    async created() {
      this.allowedToDecline = this.$auth.isAllowed('estimates', 'decline');

      // Get the access token from the auth wrapper
      const accessToken = await this.$auth.getTokenSilently();

      //Retrieve the estimate
      this.estimate = await Jobs.getEstimate(this.uuid, accessToken);

      if (this.estimate) {
        // console.log( "estimate:", this.estimate );

        // get the estimator (company contact) details
        this.getEstimator();

        // get client info (customer paying for the service)
        this.getClient();

        // Retrieve the related job site
        this.jobSite = await Clients.getJobSite(
          this.estimate.job_site_uuid,
          accessToken
        );

        // Load trees in the job site
        const trees = await Clients.getTreesByJobSite(
          this.estimate.job_site_uuid
        );

        // console.log( "trees:", trees )
        if (trees && trees.length > 0) {
          this.trees = await Jobs.getWorkTaskCountByTree(
            this.estimate.uuid,
            trees
          );
        }

        // Load tenant record to get current logo
        this.treeCareLogo = this.$auth.tenantProfile.logo;
      }

      //get tenant info based on subdomain
      // var currentHost = window.location.hostname;
      // var urlParts = currentHost.split(".");
      // var currentSubDomain = urlParts[0];
      // var currentSubDomain = currentSubDomain.toLowerCase();
      // this.tenant = await this.$auth.getTenantProfileByDomain(currentSubDomain);
      this.tenant = await this.$auth.getTenantProfile();

      // get tenant settings
      // (in an authenticated situation we already have this info
      // via tenantProfile that gets loaded in $auth during the
      // begging of the user's session. This special case
      // is needed for the connected components that need this info
      // passed in for unsecured estimate proposal links)
      this.tenantSettings = this.$auth.tenantProfile
        ? this.$auth.tenantProfile
        : await Tenants.getSettingsInfo(
            this.$auth.userProfile.tenant_uuid
            // unsecured route
          );

      // generate a new PDF because estimate may have changed
      // if (this.shouldUpdateEstimatePDF()) {
      await this.generatePDF();
      // } else if (
      //   this.estimate.estimate_snapshot_urls &&
      //   this.estimate.estimate_snapshot_urls.length
      // ) {
      //   this.mostRecentProposalUrl = this.estimate.estimate_snapshot_urls[
      //     this.estimate.estimate_snapshot_urls.length - 1
      //   ];
      // }

      this.componentCreated = true;
    },
    updated() {},
    methods: {
      // get the estimator
      async getEstimator() {
        if (this.estimate.estimator_uuid) {
          this.estimator = await Users.getEstimator(
            this.estimate.estimator_uuid
          );
        }
      },
      // get client
      async getClient() {
        if (this.estimate.client_uuid) {
          this.client = await Clients.getClientofProposal(
            this.estimate.client_uuid
          );
        }
      },

      shouldUpdateEstimatePDF() {
        return (
          this.estimate &&
          this.estimate.status &&
          (this.estimate.status.toLowerCase() == 'in progress' ||
            this.estimate.status.toLowerCase() == 'revising')
        );
      },

      async copyURLToClipboard() {
        // we want to copy the public link and not the authenticated link
        await navigator.clipboard.writeText(
          window.location.hostname + '/approve/' + this.uuid
        );

        // Get the access token from the auth wrapper
        const accessToken = await this.$auth.getTokenSilently();

        // mark estimate as submitted
        this.estimate = await Jobs.submitEstimateProposal(
          this.estimate.uuid,
          accessToken
        );

        this.snackbar_message =
          'The public approval link to the Estimate Proposal has been copied to the clipboard';
        this.snackbar = true;

        this.sendOrCopyDialog = false;
      },
      saveTotalPrice(event) {
        //save current total estimate price
        this.hidden_total_price = event;
      },
      async approveProposal() {
        this.busy = true;
        this.estimate.total_price = this.hidden_total_price;

        // Get the access token from the auth wrapper
        const accessToken = await this.$auth.getTokenSilently();

        // update estimate entry in database first
        this.estimate.status = 'Approved';

        await Jobs.updateEstimate(
          this.estimate.uuid,
          this.estimate
          // unsecured route
        );

        // update job phase
        await Jobs.updateJobPhase(
          this.estimate.job_uuid,
          'Work'
          // unsecured route
        );
        // let header view know that job phase changed so that the wo breadcrumb activates
        this.$events.$emit('jobPhaseChanged');

        // update estimate approval
        await Jobs.approveEstimateProposal(this.estimate.uuid, accessToken);

        // disabled until event creation is vetted and assigned
        // this.createEvent({
        //   link: window.location.href,
        //   users: [this.client.uuid, this.estimate.tenant_uuid],
        //   notification_name: 'ESTIMATE-APPROVED',
        //   clientUuid: this.client.uuid,
        // });
        this.snackbar_message = 'Estimate Proposal was successfully Approved';
        this.snackbar = true;
        this.busy = false;
        this.generatePDF();
      },
      async declineProposal() {
        this.busy = true;

        // close dialog
        this.declineEstimateConfirm = false;

        // Get the access token from the auth wrapper
        const accessToken = await this.$auth.getTokenSilently();

        // update estimate approval
        const res = await Jobs.declineEstimateProposal(
          this.estimate.uuid,
          accessToken
        );

        if (res) {
          this.generatePDF();
          this.busy = false;
        }
      },
      async generatePDF() {
        this.mostRecentProposalUrl = '';

        // send request to the api
        let res = await Jobs.createEstimateProposalPDF({
          uuid: this.estimate.uuid,
        });

        // validate response
        if (res) {
          this.mostRecentProposalUrl = res.location;
        } else {
          console.log('error creating new estimate proposal');
        }
      },
      async reviseEstimate() {
        const date = new Date().toLocaleString().split(',')[0];

        // send revision request to api
        const res = await Jobs.updateEstimate(this.estimate.uuid, {
          add_revision_comment: date + ' -- ' + this.revision_comment,
        });

        if (res) {
          this.estimate = res;
          this.snackbar_message = 'Revision request successfully sent';
          this.snackbar = true;
          this.revisionCommentDialog = false;

          // disabled until event creation is vetted and assigned
          // this.createEvent({
          //   link: window.location.href,
          //   users: [this.client.uuid, this.estimate.tenant_uuid],
          //   notification_name: 'ESTIMATE-RETURNED-FOR-REVISION',
          //   clientUuid: this.client.uuid,
          // });
        } else {
          console.log('something went wrong revising proposal ');
        }
      },

      async onSubmitEstimate() {
        // close dialogs
        this.sendOrCopyDialog = false;
        this.submitEstimateConfirm = false;
        this.snackbar_message =
          'The Estimate Proposal has been sent to the Client';
        this.snackbar = true;

        // Get the access token from the auth wrapper
        const accessToken = await this.$auth.getTokenSilently();
        this.estimate = await Jobs.submitEstimateProposal(
          this.estimate.uuid,
          accessToken
        );

        this.sendEstimateEmail();
      },

      sendEstimateEmail() {
        // send email with the public approval link
        this.createEmail({
          link: window.location.hostname + '/approve/' + this.uuid,
          recipients: [this.client.contact_email],
          notification_name: 'ESTIMATE-SUBMITTED',
        });
      },
    },
  };
</script>

<!-- Add 'scoped' attribute to limit CSS to this component only -->
<style scoped lang="scss">
  .offscreen {
    position: absolute;
    top: -10000px;
    left: -10000px;
  }

  h3 {
    margin: 40px 0 0;
  }
  ul {
    list-style-type: none;
    padding: 0;
  }
  li {
    display: inline-block;
    margin: 0 10px;
  }
  a {
    color: #42b983;
  }

  #estimate-proposal-page {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    background-color: white;
    width: 90%;
  }

  #estimate-proposal-page .layout {
    padding: 8px !important;
  }
</style>
